html, body {
	height: 100%;
	margin: 0;
	font-size: 100%;
	padding: 0;
}

#main {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

#app {
	width: 100%;
	height: 100%;
	display: flex;

}

.page-container{
	display: flex;
	flex-direction: column;
	min-height:100vh;
	
}

.content-wrap{
	flex:1;
}

/* raleway-900 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('./fonts/raleway-v18-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/raleway-v18-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fjalla-one-regular - latin */
@font-face {
  font-family: 'Fjalla One';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./fonts/fjalla-one-v8-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/fjalla-one-v8-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

::-webkit-scrollbar {display:none;}

@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-left-nav {
  transform: scale(0.5) translateY(-100%);
  -webkit-transform: scale(0.5) translateY(-100%);
}

.image-gallery-right-nav {
  transform: scale(0.5) translateY(-100%);
  -webkit-transform: scale(0.5) translateY(-100%);
}